@import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Italianno&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    overflow-x: hidden; /* Hide horizontal scrollbar */
    font-family: 'Inter', sans-serif; /* Set the font family */
    font-size: 1rem; /* Default font size (medium) */
  
    /* Hide vertical scrollbar while allowing scrolling */
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none; /* For IE and Edge */
  }
  